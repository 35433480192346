
.video-item {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
}

.video-item img {
  width: 100%;
  height: auto;
}

.video-item .image {
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video-item .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play-icon-svg {
  transition: fill 0.2s ease-in-out;
}

.play-icon-svg svg {
  transition: fill 0.2s ease-in-out;
}

.play-icon-svg:hover circle {
  stroke: rgba(0, 0, 0, 0.434);
}

.play-icon-svg:hover path {
  stroke: rgba(0, 0, 0, 0.434);
}

.video-item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s;
}

@media (max-width: 768px) {
  .video-item {
    flex-basis: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .video-item {
    flex-basis: calc(100%);
  }
}