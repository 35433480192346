.contact-details {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 200px;
}

.contact-details h3 {
    width: auto;
    text-align: left;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.contact-body textarea {
    margin-top: 30px;
    max-height: 400px;
    height: 40px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    border-radius: unset;
    background: none;
    border: none;
    font-family: 'Poppins';
    font-size: 16px;
    color: #ffffff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #000000;
}

.contact-body textarea::placeholder {
    padding-top: 20px;
    font-family: 'Poppins';
    font-size: 16px;
}

.contact-details .contact-body {
    display: flex;
    justify-content: space-between;
}

.contact-details .contact-body .left {
    margin-top: 60px;
}

.contact-details .contact-body .right .summary-card {
    width: 400px;
    border: 1px solid #ffffff;
    filter: drop-shadow(0px 4px 10px rgba(250, 244, 244, 0.75));
    border-radius: 30px;
    box-sizing: border-box;
    background-color: #ffffff;
    padding: 40px;
}

.contact-details .contact-body .right .summary-card h5 {
    font-family: "Inter";
    font-weight: normal;
    font-size: 20px;
    line-height: 27.5px;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 0px;
    letter-spacing: 0.1em;
    margin-bottom: 50px;
}

.contact-details .contact-body .left button {
    background-color: #83ACB9;
    height: 60px;
    border-radius: 32px;
    font-family: "roboto";
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.1em;
    color: white;
    text-transform: uppercase;
    padding-left: 58px;
    padding-right: 58px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-width: 0;
    margin-top: 30px;
    cursor: pointer;
}

.contact-details .contact-body .right .summary-card .request-row {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}

.contact-details .contact-body .right .summary-card .request-row .label {
    color: #b98860;
    font-family: "roboto";
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-transform: capitalize;
    margin-top: 0px;
    margin-bottom: 0px;
}

.contact-details .contact-body .right .summary-card .request-row .value {
    color: #ffffff;
    font-family: "Inter";
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.contact-details input {
    display: inline;
    border: none;
    outline: none;
    color: #ffffff;
    background: #0F0F0F;
    font-family: "Inter";
    font-size: 16px;
    font-weight: normal;
    line-height: 21px;
    margin-bottom: 0px;
    margin-top: 40px;
    width: 100%;
    min-width: 500px;
}

.contact-details .line {
    background-color: #83ACB9;
    height: 2px;
    width: 100%;
    margin-top: 12px;
}

@media only screen and (max-width: 1000px) {
    .contact-details .contact-body .left {
    margin-top: 40px;
    }
    .contact-details .contact-body {
    flex-direction: column;
    }

    .contact-details .contact-body .left {
    width: 100%;
    }

    .contact-details .contact-body .right {
    margin-top: 100px;
    display: flex;
    justify-content: space-around;
    }

    .contact-details .contact-body .right .contact-info {
    margin-top: 0px;
    }
}

@media only screen and (max-width: 550px) {
    .contact-details input {
    min-width: 200px;
    }
    .contact-details .contact-body .right .summary-card {
    width: 80%;
    padding: 20px;
    }

    .contact-details .contact-body .left button {
    height: 50px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
    }
}
