.service-text-component {
    padding-top: 40px;
}

.service-text-component h3 {
    text-align: left;
    padding-left: 60px;
    padding-right: 60px;
}


.service-text-component p {
    margin-top: 40px;
    text-align: left;
}

@media (max-width: 768px) {
    
    .service-text-component h1 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .service-text-component p {
        width: auto;
        padding-left: 20px;
        padding-right: 20px;
    }

    .service-text-component h3 {
        padding-left: 20px;
        padding-right: 20px;
        width: auto;
    }

}