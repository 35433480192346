.service-list-component {
    padding-top: 20px;
    padding-bottom: 70px;
}

.service-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.service-list-component h3 {
    text-align: left;
}

.service-list-component .videoHolder {
    margin-top: 50px;
    padding-left: 40px;
    padding-right: 40px;
}

.modal-video-body {
    max-width: none !important;
    background: #000000 !important;
}

@media (max-width: 600px) {

    .service-list-component h3 {
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;
        width: auto;
    }

    .service-list-component .videoHolder {
        padding-left: 10px;
        padding-right: 10px;
    }

}