.home-welcome {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100vh;
}

.welcome-header-center {
    position: absolute;
    top: 40%;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.welcome-header-center h1 {
    font-family: 'DescovarAlpha';
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    letter-spacing: 5px;
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    letter-spacing: 0.14em;
    color: #292929;
}

.welcome-header-center p {
    font-family: 'Marcellus';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    width: calc(100% - 120px);
    padding-left: 60px;
    padding-right: 60px;
    margin-top: 20px;
    margin-bottom: 0px;
    text-align: center;
    letter-spacing: 0.14em;
    color: #292929;
}

.welcome-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    height: 100%;
    width: 100%;
}

.color-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.video-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}
  
.video-container video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.icon-container {
    position: absolute;
    top: 85%;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
	justify-content: center;
}

.icon-container .icon {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 300px;
}

.icon-container .scroll {
	width: 60px;
	height: 60px;
	border: 2px solid #ffffff;
	border-radius: 50%;
	position: relative;
	animation: down 1.5s infinite;
	-webkit-animation: down 1.5s infinite;
	&::before {
		content: '';
		position: absolute;
		top: 15px;
		left: 20px;
		width: 18px;
		height: 18px;
		border-left: 2px solid #ffffff;
  	    border-bottom: 2px solid #ffffff;
		transform: rotate(-45deg);
	}
}

@keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

@-webkit-keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

.navbar {
    position: absolute;
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
}
  
.field {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
}
  
.mouse {
    width: 27px;
    height: 50px;
    border: 1px solid #ffffff;
    border-radius: 60px;
    position: relative;
}
  
.mouse::before {
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff;
    border-radius: 50%;
    opacity: 1;
    animation: wheel 2s infinite;
    -webkit-animation: wheel 2s infinite;
}

@media only screen and (max-width: 900px) {
    .home-welcome {
      width: 100%;
    }
  }
  