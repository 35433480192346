.drone-item {
    flex-basis: calc(33.33% - 20px);
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    margin-bottom: 60px;
} 
  
.drone-item img {
    width: 100%;
    height: auto;
}
  
.drone-item .image {
    display: block;
    max-width: 100%;
    height: auto;
}
  
.drone-item .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s;
}

.drone-name h3 {
    margin-top: 30px;
    font-size: 25px;
    text-align: center;
    min-height: 60px;
    width: auto;
    padding-left: 0px;
    padding-right: 0px;
}

.drone-text p {
    line-height: 24px;
    font-size: 15px;
    margin-top: 20px;
    width: auto;
    padding-left: 2%;
    padding-right: 2%;
    text-align: left;
}
  
@media (max-width: 768px) {
    .drone-item {
      flex-basis: calc(50% - 20px);
    }
}
  
@media (max-width: 480px) {
    .drone-item {
      flex-basis: calc(100% - 20px);
    }
}