.header-text-components {
    padding-top: 200px;
    padding-bottom: 30px;
}

.header-text-components h1 {
    text-align: left;
    padding-left: 60px;
    padding-right: 60px;
}

.header-text-components p {
    margin-top: 50px;
    text-align: left;
}

@media (max-width: 768px) {
    
    .header-text-components h1 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .header-text-components p {
        width: auto;
        padding-left: 20px;
        padding-right: 20px;
    }

}