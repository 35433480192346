.service-production-text-component {
    padding-top: 70px;
    padding-bottom: 70px;
}

.service-production-text-component h3 {
    text-align: left;
    padding-left: 60px;
    padding-right: 60px;
}

.service-production-text-component p {
    text-align: left;
    margin-top: 40px;
    line-height: 30px;
}

.link-style {
    color: rgb(153, 198, 212);
    text-decoration: none;
    font-weight: bold;
}

@media (max-width: 768px) {
    .service-production-text-component p {
        padding-left: 20px;
        padding-right: 20px;
        width: auto;
    }

    .service-production-text-component h3 {
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;
    }
}
