.service-item {
    flex-basis: calc(33.33% - 20px);
    margin-bottom: 60px;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
}
  
.service-item img {
    width: 100%;
    height: auto;
}
  
.service-item .image {
    display: block;
    max-width: 100%;
    height: auto;
}
  
.service-item .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
  
.play-icon-svg {
    transition: fill 0.2s ease-in-out;
}
  
.play-icon-svg svg {
    transition: fill 0.2s ease-in-out;
}
  
.play-icon-svg:hover circle {
    stroke: rgba(0, 0, 0, 0.434);
}
  
.play-icon-svg:hover path {
    stroke: rgba(0, 0, 0, 0.434);
}

.service-item video {
    filter: none !important;
}

.service-item .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s;
}

.service-item p {
    margin-top: 0px;
    width: auto;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 18px;
}
  
@media (max-width: 768px) {
    .service-item {
      flex-basis: calc(50% - 20px);
    }
}
  
@media (max-width: 480px) {
    .service-item {
      flex-basis: calc(100%);
    }
}