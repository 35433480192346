.dron-component {
    margin-top: 180px;
    margin-bottom: 70px;
}

.drone-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 40px;
}

.dron-component h3 {
    text-align: left;
    padding-left: 60px;
    padding-right: 60px;
}

@media (max-width: 768px) {
    .dron-component h3 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .drone-list {
        padding-left: 10px;
        padding-right: 10px;
    }
}