.navbar {
    padding-left: 5%;
    padding-right: 5%;
    height: 80px;
    z-index: 2;
  }
  
  .navbar .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }
  
  .is-active {
    color: rebeccapurple;
  }
  
  .navbar .top .left ul {
    padding-left: 0px;
    list-style: none;
    display: flex;
  }
  
  .navbar .top .right ul {
    list-style: none;
    display: flex;
  }
  
  .navbar .top .right img {
    display: none;
  }
  
  .navbar .top .left img {
    margin-top: 10px;
  }
  
  .navbar .top .center {
    position: absolute;
    top: 34.93px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .navbar .top .left ul li,
  .navbar .top .left ul li a {
    font-family: DescovarAlpha;
    font-weight: normal;
    margin-right: 30px;
    letter-spacing: 2px;
    color: #ffffff;
    font-size: 18px;
    line-height: 22px;
    text-decoration: none;
  }
  
  .navbar .top .right ul li,
  .navbar .top .right ul li a {
    font-family: DescovarAlpha;
    font-weight: normal;
    margin-left: 30px;
    letter-spacing: 2px;
    color: #ffffff;
    font-size: 18px;
    line-height: 22px;
    text-decoration: none;
  }
  
  #hamburger-menu {
    background-color: #292929;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    z-index: 1000;
  }
  
  #hamburger-menu img {
    margin: 60px;
    width: 30px;
    height: 30px;
    position: relative;
    margin-left: -20px;
    margin-top: 30px;
    top: 0;
    left: 50%;
  }
  
  #hamburger-menu ul {
    display: block;
    text-align: end;
    margin-right: 20px;
  }
  
  #hamburger-menu ul li {
    margin-bottom: 40px;
    list-style: none;
  }
  
  #hamburger-menu ul li a {
    font-family: "outfit";
    font-weight: 600;
    font-size: 30px;
    color: #ffffff;
    text-decoration: none;
  }
  
  @media only screen and (max-width: 550px) {
    .navbar .top .right ul {
      display: none;
    }
  
    .navbar .top .right img {
      margin-top: 30px;
      display: block;
    }
  
    .navbar .top .left ul {
      display: none;
    }
  
    .navbar .top .left img {
      display: block;
    }
  }
  