.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro&display=swap");

@font-face {
  font-family: 'DescovarAlpha';
  font-weight: normal;
  src: local('DescovarAlpha'), url(./fonts/DescovarAlpha/Decovar-Alpha-regular24.ttf) format('truetype');
}

@font-face {
  font-family: 'Marcellus';
  font-weight: normal;
  src: local('Marcellus'), url(./fonts/Marcellus/Marcellus-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: normal;
  src: local('Inter'), url(./fonts/Inter/Inter-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: bold;
  src: local('Inter'), url(./fonts/Inter/Inter-Bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: local('Inter'), url(./fonts/Inter/Inter-SemiBold.ttf) format('truetype');
  font-display: swap;
}

html {
  background-color: #0F0F0F;
}

/* default sizes */

h1 {
  font-family: 'DescovarAlpha';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
  letter-spacing: 0.14em;
  color: #ffffff;
}

h2 {
  font-family: 'DescovarAlpha';
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
  letter-spacing: 0.14em;
  color: #ffffff;
}

h3 {
  font-family: 'DescovarAlpha';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  width: calc(100% - 120px);
  padding-left: 60px;
  padding-right: 60px;
  margin-top: 6px;
  margin-bottom: 0px;
  text-align: center;
  letter-spacing: 0.14em;
  color: #ffffff;
}

p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: calc(100% - 120px);
  padding-left: 60px;
  padding-right: 60px;
  margin-top: 6px;
  margin-bottom: 0px;
  text-align: center;
  letter-spacing: 0.14em;
  color: #ffffff;
}