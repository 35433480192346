.footer-component {
    height: 288px;
    width: 100%;
    background: #292929;
    display: flex;
    align-items: center;
    margin-top: 80px;
}

.left-section {
    margin-left: 60px;
    flex: 1;
    display: flex;
    justify-content: center; 
    align-items: center; 
}
  
.middle-section {
    margin-left: 20px;
    flex: 2;
}
  
.text-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
  
.icon {
    width: 20px;
    margin-right: 10px; 
}
  
.text-row p {
    margin: 0;
    padding: 0px;
    width: auto;
    letter-spacing: 0px;
    text-align: left;
}
  
.right-section {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 60px;
}

.right-section a {
    margin-left: 15px;
    margin-right: 15px;
}

@media (max-width: 768px) {
    .footer-component {
        height: auto;
        flex-direction: column;
        align-items: center; 
        justify-content: center;
    }

    .left-section,
    .middle-section,
    .right-section {
      width: 100%; 
      text-align: center; 
    }

    .left-section {
        padding-top: 30px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .middle-section {
        padding-left: 20px;
        padding-right: 20px;
        width: auto;
        padding-top: 30px;
    }

    .right-section {
        order: 3;
        width: 80%;
        padding-left: 10%;
        padding-right: 10%;
        margin-top: 20px;
        margin-bottom: 40px;
        margin-right: 0px;
        margin-left: 0px;
        flex: 1 1;
        display: flex;
        justify-content: center;
      }
  }