.privacy-text-component {
    padding-top: 140px;
    padding-bottom: 70px;
}

.privacy-text-component p {
    margin-top: 16px;
    padding-left: 10%;
    padding-right: 10%;
    width: 80%;
    line-height: 30px;
}

.privacy-text-component ul {
    list-style-type: none;
}

.privacy-text-component li {
    color: white;
}

@media (max-width: 768px) { 

    .privacy-text-component h2 {
        padding-left: 20px;
        padding-right: 20px;
        width: auto;
    }

    .privacy-text-component p {
        padding-left: 20px;
        padding-right: 20px;
        width: auto;
    }
}