.home-text-component {
    padding-top: 80px;
    padding-bottom: 70px;
}

.home-text-component h3 {
    text-align: left;
}

.home-text-component p {
    text-align: left;
    margin-top: 40px;
    line-height: 30px;
}

.link-style {
    color: rgb(153, 198, 212);
    text-decoration: none;
    font-weight: bold;
}

@media (max-width: 600px) { 

    .home-text-component h3 {
        padding-left: 20px;
        padding-right: 20px;
        width: auto;
    }

    .home-text-component p {
        padding-right: 20px;
        padding-left: 20px;
        width: auto;
    }

}